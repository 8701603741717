var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-search-wrap"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "search-select v-input--density-compact",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "items": _vm.searchKeys,
      "item-text": "text",
      "item-value": "value"
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--width-fixed v-input--density-compact",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "placeholder": "검색어를 입력하세요."
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return function () {
          _vm.page = 1;
          _vm.search();
        }.apply(null, arguments);
      }
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-primary', {
    on: {
      "click": function () {
        _vm.page = 1;
        _vm.search();
      }
    }
  }, [_vm._v("검색")])], 1)], 1)], 1), _c('div', {
    staticClass: "board-list-faq"
  }, [_c('v-expansion-panels', {
    attrs: {
      "accordion": ""
    }
  }, _vm._l(_vm.faqs, function (faq, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', [_c('div', {
      staticClass: "board-list-faq__header"
    }, [_c('div', {
      staticClass: "board-list-faq__header-mark"
    }, [_c('span', [_vm._v("Q")])]), _c('div', {
      staticClass: "board-list-faq__tit"
    }, [_c('span', [_vm._v(_vm._s(faq.subject))])])])]), _c('v-expansion-panel-content', [_c('div', {
      staticClass: "board-list-faq__content"
    }, [_c('div', {
      staticClass: "board-list-faq__content-mark"
    }, [_c('span', [_vm._v("A")])]), _c('div', {
      staticClass: "board-list-faq__txt"
    }, [_c('span', {
      staticClass: "board-list-faq__html",
      domProps: {
        "innerHTML": _vm._s(faq.content.replace(/\n/g, '<br>'))
      }
    })])])])], 1);
  }), 1)], 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "11"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }